.company-logo2 .login-img2 {
    border-radius: 0.25rem;
}

/* // X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    .company-logo2 .login-img2 {
        border-radius: 0px;
    }

 }

/* // Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .company-logo2 .login-img2 {
        border-radius: 0px;
    }

 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { }

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { }