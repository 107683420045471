.company-logo1 .login-img1 {
    border-radius: 0.25rem;
}

/* // X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    .company-logo1 .login-img1 {
        border-radius: 0px;

        /* src: {require("../../../src/assets/img/Travel_App_Header.jpg")}; */
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
.hidescroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescroll {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}


 }

/* // Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .company-logo1 .login-img1 {
        border-radius: 0px;

    }

 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .swiper-pagination {
        bottom: 0;
        padding-bottom: 20px;
      }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { }

.bgroundContainer1 {
    position: relative;
  }
  
  .bgroundContainer1:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background-image: url('../../../src/assets/img/mercantileBG1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    opacity: 0.4;
  }
  
  .bgroundContainer1 .mainContent1{
    position: relative;
  }

