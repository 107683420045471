.offcanvas-body .navbar-dark .text-center img {
    border-radius: 20px;
  }

  .product-services .card-ps {
    height: 100%;
    border-radius: 20px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  }

  .product-services .card-ps .card-ps-bottom {
    border-radius: 0 0 20px 20px;
  }

  .product-services .card-ps .btn-right {
    background-color: blue;
    position: absolute;
    bottom: 20px;
    right: 0px;
    height: 50px;
    width: 80px;
    border-radius: 50px 0px 0px 50px;
    display: flex;
  }
  .product-services .card-ps .btn-right i {
    color: white;
    font-size: 40px;
    align-self: center;
    position: absolute;
    right: 0px;
    padding: 18px;
  }

  .no-scroll-container::-webkit-scrollbar {
    display: none;
  }

  .text-limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /*number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .payment-css {
    border-radius: 20px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  }