* {
    color: #3D3D3D;
}

.homepage-button {
    border: none;
    background-color: #9CB8BD;
    font-size: 1.25rem;
}

.homepage-button:hover {
    background-color: #AECACF;
}

.homepage-heading {
    font-weight: 900;
    font-size: 3rem;
}

.homepage-body {
    font-size: 1.25rem;
    font-weight: 500;
}

