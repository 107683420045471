/* basic styles */
.skeleton {
  background: #fff;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
}
.skeleton.text {
  background: rgb(236, 230, 230);
  width: 100%;
  height: 12px;
}
.skeleton.title {
  background: rgb(236, 230, 230);
  width: 100%;
  height: 20px;
  margin: 1px 1px;
}
.skeleton.avatar {
  background: rgb(236, 230, 230);
  margin: 24px 30px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.skeleton.thumbnail {
  width: 100px;
  height: 100px;
}
.skeleton-wrapper {
  margin: 20px;
  padding: 10px 15px;
}

/* skeleton profile */
.skeleton-profile {
  display: grid;
  grid-template-columns: 0fr 5fr;
  grid-gap: 1px;
  align-items: center;
}
